/* eslint-disable import/prefer-default-export */
/* eslint-disable no-lonely-if */

import { domesticServices, internationalServices } from './ServicesNew';
import { countryList } from './CountriesNew';

export const getShippingZone = (countryLabel) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  // console.log('ZONEE');
  // console.log(country.zone);
  return country.zone;
};

export const getDhlShippingZone = (countryLabel) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  // console.log('ZONEE');
  // console.log(country.zone);
  return `D${country.dhlZone}`;
};

export const getAvailableTrackedService = (countryLabel, size) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  if (size === 'SP') {
    if (country.services.includes('MTA')) {
      return 'MTA';
    } else if (country.services.includes('MP1')) {
      return 'MP1';
    } else if (country.services.includes('MP5')) {
      return 'MP5';
    }
  } else {
    if (country.services.includes('MTC')) {
      return 'MTC';
    } else if (country.services.includes('MTI')) {
      return 'MTI';
    } else if (country.services.includes('MTM')) {
      return 'MTM';
    }
  }
  return null;
};

export const getServicePrice = (weight, serviceCode, countryLabel, size) => {
  let weightGroup;
  let service;
  const country = countryList.find(ctry => ctry.label === countryLabel);
  console.log(countryList);
  console.log(countryLabel);
  if (country.zone === 'DOMESTIC') {
    service = domesticServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
  } else {
    service = internationalServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
    console.log(service);
  }

  if (weight && country && service && size) {
    switch (true) {
      case weight <= 250 && weight >= 1:
        weightGroup = 250;
        break;
      case weight > 250 && weight <= 500:
        weightGroup = 500;
        break;
      case weight <= 750 && weight > 500:
        weightGroup = 750;
        break;
      case weight <= 1000 && weight > 750:
        weightGroup = 1000;
        break;
      case weight <= 1250 && weight > 1000:
        weightGroup = 1250;
        break;
      case weight <= 1500 && weight > 1250:
        weightGroup = 1500;
        break;
      case weight <= 2000 && weight > 1500:
        weightGroup = 2000;
        break;
      case weight > 2000 && weight <= 20000 && country.zone === 'DOMESTIC':
        weightGroup = 20000;
        break;
      default:
    }

    let price;

    if (weightGroup > 0) {
      if (country.zone === 'DOMESTIC') {
        if (size === 'SP') {
          if (weightGroup <= 20000 && weightGroup > 2000) {
            weightGroup = 20000;
          } else if (weightGroup <= 2000 && weightGroup > 1000) {
            weightGroup = 2000;
          } else {
            weightGroup = 1000;
          }
        } else if (service.value === 'SD1' && weightGroup < 1000) {
          weightGroup = 500;
        }
        price = service.prices[weightGroup];
      } else {
        if (size === 'SP') {
          if (weightGroup < 500) {
            weightGroup = 500;
          }
        }
        if (serviceCode === 'GPT' || serviceCode === 'GPP') {
          price = service.prices[weightGroup][country.dhlZone];
        } else {
          price = service.prices[weightGroup][country.zone];
        }
      }
      return price;
    }
  }
  return null;
};

export const isTaxable = (countryLabel) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  let isEu = false;
  if (country.zone === 'EU' || country.zone === 'DOMESTIC') {
    isEu = false;
  }
  return isEu;
};

export const getMMServicePrice = (weight, serviceCode, countryLabel, size) => {
  const country = countryList.find(ctry => ctry.label === countryLabel);
  console.log(countryLabel);
  let service = null;

  let cost = 0.0;

  if (country.zone === 'DOMESTIC') {
    let weightGroup;

    switch (true) {
      case weight <= 100 && weight >= 1:
        weightGroup = 100;
        break;
      case weight > 100 && weight <= 250:
        weightGroup = 250;
        break;
      case weight > 250 && weight <= 500:
        weightGroup = 500;
        break;
      case weight <= 750 && weight > 500:
        weightGroup = 750;
        break;
      case weight <= 1000 && weight > 750:
        weightGroup = 1000;
        break;
      case weight <= 1250 && weight > 1000:
        weightGroup = 1250;
        break;
      case weight <= 1500 && weight > 1250:
        weightGroup = 1500;
        break;
      case weight <= 2000 && weight > 1500:
        weightGroup = 2000;
        break;
      case weight > 2000 && weight <= 20000:
        weightGroup = 20000;
        break;
      default:
    }
    if (size === 'SP') {
      if (weightGroup < 1000) {
        weightGroup = 1000;
      }
    }
    if (serviceCode === 'SD1') {
      if (weightGroup < 500) {
        weightGroup = 500;
      } else if (weightGroup > 500 && weightGroup <= 1000) {
        weightGroup = 1000;
      } else if (weightGroup > 1000 && weightGroup <= 2000) {
        weightGroup = 2000;
      }
    }
    service = domesticServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
    cost = service.obaPricing[weightGroup];
  } else {
    service = internationalServices.find(serv => serv.value === serviceCode && serv.sizes.includes(size));
    console.log('blblblblb');
    console.log(internationalServices);
    console.log('SIZE');
    console.log(size);
    console.log('SERVICE');
    console.log(service);
    console.log();
    let zone = 'EU';
    if (serviceCode !== 'GPT' && serviceCode !== 'GMP') {
      zone = country.value;
      if (!service.obaPricing[zone]) {
        if (country.zone !== 'EU') {
          if (country.zone === 3) {
            zone = 'USA';
          } else {
            zone = 'ROZ2';
          }
        } else {
          zone = 'ROEU';
        }
      }
    } else {
      zone = country.dhlZone;
    }

    const pricePerItem = service.obaPricing[zone].PPI;
    let pricePerKilo = service.obaPricing[zone].PPK;

    if (serviceCode === 'GPT' || serviceCode === 'GMP') {
      cost += (weight / 1000.0) * pricePerKilo;
      cost += pricePerItem;
    } else {
      if (zone === 'ROW') {
        pricePerKilo += 3.95; // ROYAL MAIL TEMPORARY SURCHARGE
      }
      cost += (weight / 1000.0) * pricePerKilo;
      cost += pricePerItem;
      cost += (cost * 0.065);
    }
  }
  return cost;
};
